import { useEffect, useState, Fragment, useRef } from "react";

// UI IMPORT
import { Button, Card, CardBody, CardHeader, Form } from "reactstrap";
import { MdRemoveRedEye } from "react-icons/md";
import { MdLockReset } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import PasswordModal from "./PasswordModal";

// PROJECT IMPORT
import { FILTER, showToaster, USER_LOCAL } from "../../../constant";

// THIRD - PARTY IMPORT
import UserModal from "./UserModal";
import SendNotificationButton from "./SendNotificationButton";
import { useForm } from "react-hook-form";

import {
    getLocalDataa, responseToaster,
    setLocalData,
} from "../../../helperFunctions";

import BankAsignModal from "./BankAsignModal";
import { useLocation, useNavigate } from "react-router";
import { FaRegEye } from "react-icons/fa6";
import Avatar from "../../../components/Custom/Elements/Avatar/Avatar";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import { Table, TableRow } from "../../../components/Table";
import { useDispatch } from "react-redux";
import { userDelete, usersGetList, useUsersList } from "../../../redux/slices/smsSlice";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import DeleteModal from "../../../components/Custom/Modals/DeleteModal/DeleteModal";
import { searchDataOptions } from "./mock";
import { useRole } from "../../../redux/slices/authSlice";
import ResetPassword from "./UsersResetPassword";
import { IoMdEyeOff } from "react-icons/io";

const User = () => {
    const navigate = useNavigate();
    const route = useLocation()?.pathname;
    const dispatch = useDispatch();
    const role = useRole("Sms Sync");
    const userData = useUsersList();
    const [filter, setFilter] = useState(getLocalDataa(USER_LOCAL, FILTER));
    const [listLoading, setListLoading] = useState(false)

    const [asignBankModal, setAsignBankModal] = useState(false);
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [deleteID, setDeleteID] = useState("");
    const [tableData, setTableData] = useState([]);
    const [currentData, setCurrentData] = useState({});
    const isInitialMount = useRef(true);
    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false)
    const [isResetID, setIsResetID] = useState("");
    const [isCopy, setIsCopy] = useState();
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isUserLoading, setIsUserLoading] = useState(false)
    const [isOpenConfirmation, setisOpenConfirmation] = useState(false);
    const [filterColumns, setFilterColumns] = useState([]);
    const [userResetPassword, setUserResetPassword] = useState(false);

    const { handleSubmit, control, reset, getValues, watch } = useForm({
        defaultValues: {
            filter: searchDataOptions?.[0]?.value,
            search: "",
        },
    });

    const onDeleteUser = async () => {
        try {
            setIsUserLoading(true)
            const res = await dispatch(userDelete({ id: deleteID })).unwrap();
            setisOpenConfirmation(false);
            responseToaster(res);
            setFilter({ ...filter, isFilter: false });
            setIsUserLoading(false)

        } catch (err) {
            setIsUserLoading(false)

        }
    };

    const PasswordCell = ({ data }) => {
        const [showPassword, setShowPassword] = useState(false);

        const togglePasswordVisibility = (e) => {
            e.stopPropagation();
            setShowPassword(!showPassword);
        };

        return (
            <>
                {data?.text_password ? (
                    <>
                        {showPassword ? data?.text_password : '•'.repeat(data?.text_password?.length)}
                        <span onClick={togglePasswordVisibility}>
                           {" "} {showPassword ? <IoMdEyeOff  /> : <MdRemoveRedEye />}
                        </span>
                    </>
                ) : (
                    "-"
                )}
            </>
        );
    };

    const columns = [
        {
            title: "#",
            Cell: () => (
                <FaRegEye />
            ),
        },
        {
            title: "Username",
            name: "user_name",
            sortableKey: "user_name",
            sortable: true,
            Cell: (item) => {
                return (
                    <div className="d-flex gap-50 align-items-center">
                        <Avatar
                            name={item?.user_name}
                            className="!bg-[#f1f1f1] h-[25px] w-[30px] mr-2 !text-base text-secondary"
                            sx={{ width: 30, height: 30, fontSize: 14 }}
                        />
                        <div className="text-primary gap-2">{item?.user_name}</div>
                    </div>
                );
            },
        },
        {
            title: "Merchant Name",
            name: "merchant_name",
        },
        {
            title: "Devices",
            name: "login_count",
            Cell: (item) => {
                return item?.login_count || 0;
            },
        },
        {
            title: "Registration Date",
            name: "created_at_ist",
            sortableKey: "created_at_ist",
            sortable: true,
        },
        {
            title: "Password",
            name: "text_password",
            Cell: (data) => {
                return <>
                    <PasswordCell data={data} />
                </>
            }
        },
        {
            title: "Action",
            Cell: (data, e) => {
                return (
                    <>
                        <div className="d-flex gap-50 align-items-center">
                            {/*  {role.includes("update-sms-user") ? <Button title="Edit" color="primary" onClick={(e) => {
                                setIsUserModalOpen(true);
                                setCurrentData(data);
                                e.stopPropagation();
                            }}>
                                <MdModeEditOutline />
                            </Button> : null} */}
                            {role.includes("reset-sms-user-password") ? <Button title="Reset Password" color="primary" onClick={(e) => {
                                setUserResetPassword(data)
                                e.stopPropagation();
                            }}>
                                <MdLockReset />
                            </Button> : null}

                            {role.includes("delete-sms-user") ? <Button title="delete" color="danger" onClick={(e) => {
                                setisOpenConfirmation(true);
                                setDeleteID(data?.id);
                                e.stopPropagation();
                            }}>

                                <MdDelete />
                            </Button> : null}

                            {data?.inActiveUser !== 0 && role.includes("send-notification") ? (
                                <Button title="send notification" color="primary" >
                                    <SendNotificationButton
                                        classNameJustIcon="cursor-pointer bg-chatlook-secondary rounded-md"
                                        userName={data?.user_name ?? ""}
                                        isBulk
                                        justIcon
                                        size={16}
                                        userId={data?.id}
                                    />
                                </Button>
                            ) : null}
                        </div>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        const newColumns = columns?.map((item) => item?.name);
        setFilterColumns(newColumns);
    }, []);

    const onGetData = async () => {
        try {
            setListLoading(true)
            const {
                isFilter,
                page_no,
                limit,
                filter: dropdown,
                search,
                ...rest
            } = filter;
            const payload = isFilter
                ? {
                    page_no,
                    limit,
                    filter_data: {
                        [dropdown]: search,
                        ...rest,
                    },
                }
                : {
                    page_no,
                    limit,
                };

            const res = await dispatch(usersGetList(payload)).unwrap();
            if (isFilter) {
                responseToaster(res);
            }
            setListLoading(false)
        } catch (err) {
            setListLoading(false)
        }
    };

    useEffect(() => {
        if (role.includes("sms-user-list")) {
            onGetData();
        }
        // eslint-disable-next-line
    }, [filter]);

    useEffect(() => {
        setTableData(userData?.data?.length ? [...userData?.data] : []);
    }, [userData]);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        setLocalData(USER_LOCAL, {
            page_no: filter?.page_no,
            limit: filter?.limit,
        });
        // eslint-disable-next-line
    }, [filter?.page_no, filter?.limit]);

    const onSubmit = async () => {
        const values = getValues();
        setFilter({ ...filter, ...values, ...FILTER, isFilter: true });
    };

    if (!role.includes("sms-user-list")) {
        return
    }

    return (
        <>
            <CardBody className="pt-0">
                <div className="flex-column align-items-start">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <Form
                            className="d-flex my-xl-auto right-content align-items-end button-space"
                            style={{ flexWrap: "wrap" }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <>
                                <ControlledDropDown
                                    name="filter"
                                    label="Filter"
                                    isDefaultOption={false}
                                    options={searchDataOptions}
                                    control={control}
                                />
                                <ControlledInput
                                    placeholder={`Enter ${searchDataOptions.find(
                                        (item) => item?.value === watch("filter")
                                    )?.label
                                        }`}
                                    name="search"
                                    label="Search"
                                    autoComplete="off"
                                    control={control}
                                />

                                <Button type="submit" color="primary">
                                    Apply
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    onClick={() => {
                                        reset();
                                        setFilter({ ...FILTER });
                                    }}
                                >
                                    Clear
                                </Button>
                            </>
                        </Form>
                        <div className="d-flex">
                            {/*    <Button
                                type="button"
                                className="filterbtn me-1"
                                onClick={toggleIsFilterOpen}
                            >
                                <i className="fa-solid fa-filter"></i> Apply Filter
                            </Button> */}
                            {role.includes("add-sms-user") ? <Button
                                color="primary"
                                onClick={async () => {
                                    setIsUserModalOpen(true);
                                }}
                            >
                                Add User
                            </Button> : null}
                        </div>
                    </div>
                </div>
            </CardBody>
            <div className="App">
                <Table
                    columns={columns}
                    isLoading={listLoading}
                    isExpandable={false}
                    data={tableData}
                    isData={!!tableData?.length}
                    filterColumns={filterColumns}
                    setData={setTableData}
                    count={userData?.total_item || 0}
                    pagination={filter}
                    handlePaginationChange={(pagination) => {
                        setFilter({
                            ...filter,
                            ...pagination,
                            isFilter: false,
                        });
                    }}
                    onColumnsChange={(columns) => {
                        setFilterColumns(columns);
                    }}
                >
                    {tableData?.map((item, index) => {
                        return (
                            <Fragment key={index}>
                                <TableRow
                                    isExpandable={false}
                                    className="cursor-pointer"
                                    columns={columns}
                                    item={item}
                                    onClick={(item, e) => {
                                        e.stopPropagation()
                                        item?.login_count
                                            ? navigate(`${route}/user/${item?.id}`)
                                            : showToaster("Login Device Not Found for This User", "Error");

                                        if (item?.login_count && role.includes("sms-list")) {
                                            setLocalData("SmsTab", 1)
                                        }
                                    }}
                                    filterColumns={filterColumns}
                                ></TableRow>
                            </Fragment>
                        );
                    })}
                </Table>
            </div>
            <BankAsignModal
                data={currentData}
                isOpen={asignBankModal}
                onClose={() => {
                    setAsignBankModal(false);
                    setCurrentData({});
                }}
                getUserData={onGetData}
            />
            <UserModal
                data={currentData}
                isOpen={isUserModalOpen}
                onClose={() => {
                    setIsUserModalOpen(false);
                    setCurrentData({});
                }}
                getUserData={onGetData}
            />
            <PasswordModal
                isOpen={!!isPasswordModalOpen && !isLoadingResetPassword}
                onHide={() => {
                    setIsPasswordModalOpen(false);
                    setIsCopy({});
                }}
                isCopy={isCopy}
            />
            <DeleteModal
                isButtonLoading={isUserLoading}
                isOpen={!!isOpenConfirmation}
                title="Delete User"
                content="Are you sure you want to delete this user?"
                onCancelClick={() => {
                    setisOpenConfirmation(false);
                }}
                onDoneClick={() => {
                    onDeleteUser();
                }}
            />
            <ResetPassword
                userResetPassword={userResetPassword}
                onCloseUserResetPassword={() => setUserResetPassword(false)}
                id={userResetPassword}
                onGet={() => setFilter({ ...filter })}
            />
        </>
    );
};

export default User;
