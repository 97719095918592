import React, { useState } from "react";
import { Button, Card, CardHeader, Form } from "reactstrap";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";

// Formik validation
import {
  responseToaster,
} from "../../../helperFunctions";
import { useRole } from "../../../redux/slices/authSlice";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { vpaVerifyGet } from "../../../redux/slices/vpaVerifySlice";
import Loader from "../../../components/Custom/Loader";

const VPAVerify = () => {
  const initialValues = {
    upiId: ""
  };
  const role = useRole("Vpa Verify");
  const dispatch = useDispatch();

  const [verifyData, setVerifyData] = useState({});
  const [loading, setLoading] = useState(false)

  const schema = Yup.object({
    upiId: Yup.string()
      .required("Please enter Upi Id"),
  });

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      setVerifyData({});
      const res = await dispatch(vpaVerifyGet(values)).unwrap();

      if (res) {
        setVerifyData(res);
      }
      setLoading(false)
      responseToaster(res);
    } catch (err) {
      setLoading(false)

    }
  };

  const { control, handleSubmit, reset, formState: { errors }, } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  if (!role.includes("vpa-verify")) {
    return;
  }

  return (
    <div className="reconciliation-main-div">
      <Card>
        <CardHeader className="align-items-md-center align-items-start">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <h3>VPA Verify</h3>
          </div>
          <Form
            className="d-flex my-xl-auto right-content align-items-start button-space"
            style={{ flexWrap: "wrap" }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControlledInput
              name="upiId"
              label="Upi Id"
              placeholder="Enter Upi Id"
              control={control}
              errors={errors}
            />
            <div className="pe-1 d-flex align-items-center button-space" style={{ marginTop: "26px" }}>
              <Button
                type="submit"
                color="primary"
                disabled={loading}
                className="ms-auto"
              >
                {loading ? <Loader /> : "Apply"}
              </Button>
              <Button
                color="danger"
                className="mb-0"
                onClick={() => {
                  reset();
                  setVerifyData({});
                }}
              >
                Clear
              </Button>
            </div>
          </Form>
        </CardHeader>
      </Card>
      {verifyData?.data ? <Card>
        <div style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', padding: '1rem', height: "500px", overflow: "scroll" }}>
          {JSON.stringify?.(verifyData?.data, null, 2)}
        </div>
      </Card> : null}
    </div>
  );
};

export default VPAVerify;
