import {
  BLOCK_CUSTOMER,
  MARK_AS_FAILED_DEPOSITE,
  DEPOSITE_STATUS_UPDATE,
  GETGENERATEDREPORT,
  GET_PAYMENT_META_LABEL_LIST,
  TRANSACTION,
  TRANSACTION_FILTER_DATA,
  TRANSACTION_MODEL,
  TRANSACTION_REFUND,
  TRANSACTION_RESEND_WEBHOOK,
  TRANSACTION_SUMMARY,
  TRANSACTION_UPDATE_TEMPUTR,
  TRANSACTION_REMOVE_FEES,
  TRANSACTION_DELETE,
  GET_CUSTOMERS_DETAILS,
  BLOCK_CUSTOMER_BYHID,
  UPDATE_CHARGE_BACK,
  TRANSACTION_UPDATE_IS_USED,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Transaction = (payload) => {
  return apiInstance.post(TRANSACTION, payload);
};
export const Transaction_Filter_Data = (payload) => {
  return apiInstance.post(TRANSACTION_FILTER_DATA, payload);
};
export const Get_Generated_Report = (payload) => {
  return apiInstance.post(GETGENERATEDREPORT, payload);
};
export const Transaction_Summary = (payload) => {
  return apiInstance.post(TRANSACTION_SUMMARY, payload);
};
export const Transaction_Resend_Webhook = (payload) => {
  return apiInstance.post(TRANSACTION_RESEND_WEBHOOK, payload);
};
export const Block_Customer = (payload) => {
  return apiInstance.post(BLOCK_CUSTOMER, payload);
};
export const transactionRemoveFees = (payload) => {
  return apiInstance.post(TRANSACTION_REMOVE_FEES, payload);
};
export const transactionDelete = (payload) => {
  return apiInstance.post(TRANSACTION_DELETE, payload);
};
export const Mark_As_Failed_Deposite = (payload) => {
  return apiInstance.post(MARK_AS_FAILED_DEPOSITE, payload);
};
export const Transaction_Refund = (payload) => {
  return apiInstance.post(TRANSACTION_REFUND, payload);
};
export const Transaction_Update_Temputr = (payload) => {
  return apiInstance.post(TRANSACTION_UPDATE_TEMPUTR, payload);
};
export const Transaction_Model = (payload) => {
  return apiInstance.post(TRANSACTION_MODEL, payload);
};
export const Get_Payment_Meta_Label_List = (payload) => {
  return apiInstance.post(GET_PAYMENT_META_LABEL_LIST, payload);
};
export const Get_Deposite_Status_Update = (payload) => {
  return apiInstance.post(DEPOSITE_STATUS_UPDATE, payload);
};
export const Get_Customers_Details = (payload) => {
  return apiInstance.post(GET_CUSTOMERS_DETAILS, payload);
};
export const block_customer_byHId = (payload) => {
  return apiInstance.post(BLOCK_CUSTOMER_BYHID, payload);
};
export const update_charge_back = (payload) => {
  return apiInstance.post(UPDATE_CHARGE_BACK, payload);
};
export const Transaction_Update_Is_Used = (payload) => {
  return apiInstance.post(TRANSACTION_UPDATE_IS_USED, payload);
};
