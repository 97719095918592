import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
} from "reactstrap";
import { Table, TableRow } from "../../../components/Table";
import { useRole } from "../../../redux/slices/authSlice";
import StatusCheckModal from "../../../components/Custom/Modals/StatusCheck/StatusCheckModal";
import { statusCheckGet } from "../../../redux/slices/statusCheckSlice";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const StatusCheck = () => {
  const dispatch = useDispatch();
  const { statusCheckIsLoading, statusCheckState } = useSelector(
    (state) => state.statusCheck
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const role = useRole("Status Check Job");

  const [filterColumns, setFilterColumns] = useState([]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 4,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 4,
      backgroundColor: theme.palette.mode === "light" ? "#76bdff" : "#308fe8",
    },
  }));

  const columns = [
    {
      title: "Browser Id",
      name: "browser_id",
    },
    {
      title: "Active",
      name: "is_active",
      Cell: ((data) => {
        return +data?.is_active === 0 ? "No" : "Yes"
      })
    },
    {
      title: "Status",
      name: "on_status",
    },
    {
      title: "Interval",
      name: "time",
    },
    {
      title: "Unit",
      name: "unit",
    },
    {
      title: "Pg",
      name: "pg_name",
    },
    {
      title: "Req Id",
      name: "req_id",
    },
    {
      title: "PROGRESS",
      name: "progress",
      Cell: (data) => {
        const success = +data?.success_count || 0;
        const pending = +data?.pending_count || 0;
        const total = +data?.total_txn_count || 0;

        const completedTxn = success + pending;

        const progressPercent = total > 0 ? (completedTxn / total) * 100 : 0;

        const progressPer = progressPercent > 100 ? 100 : progressPercent;

        return (
          <>
            <div className="position-relative">
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={progressPer}
                />
              </Box>
              <div className="progress-position text-center">
                <span>{progressPer.toFixed(2)}%</span>
              </div>
            </div>
          </>
        );
      },
    },

    {
      title: "DATE",
      name: "created_at_ist",
    },

  ];

  useEffect(() => {
    const newColumns = columns?.map((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  const Get_status_check = async () => {
    try {
      await dispatch(statusCheckGet()).unwrap();
    } catch (err) { }
  };


  useEffect(() => {
    Get_status_check();
  }, []);

  if (!role.includes("status-check-job")) {
    return;
  }
  return (
    <>
      <div className="support-log-main-div">
        <Card>
          <CardHeader className="flex-column  align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <h4 className="main-title">
                <b>Status Check</b>
              </h4>
              {role?.includes("status-check-job") ? <Button
                color="primary"
                title="Bank Statement"
                disabled={statusCheckIsLoading}
                onClick={() => setIsModalOpen(true)}
              >
                Add
              </Button> : null}
            </div>
          </CardHeader>

          <Table
            columns={columns}
            isLoading={statusCheckIsLoading}
            data={statusCheckState?.data || []}
            isData={!!statusCheckState?.data?.length}
            filterColumns={filterColumns}
            isExpandable={false}
          >
            {statusCheckState?.data?.map?.((item) => {
              return (
                <TableRow
                  columns={columns}
                  item={item}
                  filterColumns={filterColumns}
                  isExpandable={false}
                />
              );
            })}
          </Table>
        </Card>
      </div>
      <StatusCheckModal
        isModalOpen={isModalOpen}
        onGet={() => Get_status_check()}
        onCloseModal={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default StatusCheck;
