import { FIXED_DECIMAL, showToaster } from "./constant";
import { logoutUserr, setloggedIn, verifyAuth } from "./redux/slices/authSlice";

const root = window.document.documentElement;

export const getAvatarText = (name) => {
  return name?.slice(0, 1)?.toUpperCase();
};

export const responseToaster = (res) => {
  if (res) {
    showToaster(
      res.message,
      res?.warning
        ? "Warning"
        : res.status
          ? "Success"
          : "Error"
    );
  } else {
    showToaster("Something Went Wrong!", "Error");
  }
};

export const verifyingUser = async (dispatch, payload) => {
  dispatch(verifyAuth(payload));
};

export const loggInUser = async (dispatch, payload) => {
  dispatch(setloggedIn(payload));
};

export const expireToken = async (dispatch, payload) => {
  dispatch(logoutUserr(payload));
};

export const capitalCase = (text) => {
  const first = text?.slice(0, 1);
  const rest = text?.slice(1);
  return `${first?.toUpperCase()}${rest?.toLowerCase()}`;
};

export const setStyles = (varr, color) => {
  root?.style.setProperty(varr, color);
};

export const seperator = (amount, isRupee = true) => {
  if (amount?.toString()?.includes(",")) {
    return `${isRupee ? "₹ " : ""}${amount}`;
  }

  const isNagative = amount?.toString()?.[0] === "-";
  const initialZeroes = Array.from({ length: FIXED_DECIMAL })?.reduce(
    (t) => t + "0",
    ""
  );

  const f = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: FIXED_DECIMAL,
  });

  let newAmount = f.format(onFixed(+Math.abs(+amount)) || 0);

  const newAmounts = newAmount?.split(".");
  const isAllZeores = (newAmounts?.[1] || "") === initialZeroes;

  if (isAllZeores) {
    newAmount = newAmounts?.[0];
  }
  newAmount = `${isNagative ? "₹ -" : ""}${isRupee && !isNagative ? "₹ " : ""
    }${newAmount?.slice(1)}`;

  return newAmount;
};

export const stringPatternToNumber = (strNumber) => {
  const number = parseFloat?.(strNumber?.toString()?.replace(/,/g, ""));
  const isInteger = number?.indexOf?.(".") === -1;
  if (!isNaN(number)) {
    const roundedNumber = number?.toFixed?.(2);
    return roundedNumber;
  } else if (isInteger) {
    return strNumber;
  } else {
    return strNumber;
  }
};

export const capitalCaseLabel = (name) => {
  const names = name?.split("_");
  let newName = "";
  names?.forEach((item, index) => {
    const first = item?.slice(0, 1);
    const rest = item?.slice(1);
    newName += `${index !== 0 ? " " : ""
      }${first?.toUpperCase()}${rest?.toLowerCase()}`;
  });
  return newName;
};

export const dateFormatter = (d, type = "start", startDate = null) => {
  if (!d && !startDate) {
    return null;
  }
  const date =
    type === "end" && !!startDate && !d ? new Date(startDate) : new Date(d);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours()?.toString()?.padStart(2, "0");
  const minute = date.getMinutes()?.toString()?.padStart(2, "0");
  const seconds = date.getSeconds()?.toString()?.padStart(2, "0");

  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")} ${type === "end"
      ? "23:59:59"
      : type === "start"
        ? "00:00:00"
        : hour + ":" + minute + ":" + seconds
    }`;
};

export const exportDateFormatter = (date = null) => {
  if (!date) {
    return "";
  }
  const day = date?.getDate()?.toString()?.padStart(2, "0");
  const month = (date?.getMonth() + 1)?.toString()?.padStart(2, "0");
  const year = date?.getFullYear();

  return `${day}-${month}-${year}`;
};

export const isParsable = (text) => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const sortByKey = (data, name, sort = "AESC") => {
  const newData = data?.length ? [...data] : [];
  const isDesc = sort === "DESC";
  const compare = (a, b) => {
    const s = name ? a?.[name] : a?.merchant_details?.merchant_name;
    const l = name ? b?.[name] : b?.merchant_details?.merchant_name;

    if (s?.toString()?.toLowerCase() < l?.toString()?.toLowerCase()) {
      return isDesc ? 1 : -1;
    }
    if (s?.toString()?.toLowerCase() > l?.toString()?.toLowerCase()) {
      return isDesc ? -1 : 1;
    }
    return 0;
  };
  return newData.sort(compare);
};

export const isCentralBankSeen = () => {
  const domain = window.location.hostname;
  // return !!centralBankUrls?.find((item) => domain?.includes(item))
  return true;
};

export const setSessionData = (key, val, defaultValue) => {
  sessionStorage.setItem(key, JSON.stringify(val ?? defaultValue));
};

export const getSessionData = (key, defaultValue = "{}") => {
  return JSON.parse(sessionStorage.getItem(key) ?? defaultValue);
};

export const setLocalData = (key, val, defaultValue) => {
  localStorage.setItem(key, JSON.stringify(val ?? defaultValue));
};

export const getLocalData = (key, defaultValue = "{}") => {
  return JSON.parse(localStorage.getItem(key) ?? defaultValue);
};

export const getLocalDataa = (key, defaultValue = {}) => {
  return JSON.parse(localStorage.getItem(key)) ?? defaultValue;
};

export const hexToRGBA = (hexCode, opacity) => {
  let hex = hexCode.replace("#", "");
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const isNavLinkActive = (item, route) => {
  if (item?.name === "Merchant" || item?.name === "SS Deposit" || item?.name === "Bank Transaction" || item?.name === "Request Response Logs") {
    const items = item?.route?.split("/")?.[1];
    const routes = route?.split("/")?.[1];
    return items === routes;
  } else {
    return item?.route === route;
  }
};

export const hasActiveChild = (item, currentURL) => {
  const { child } = item;
  if (!child) {
    return false;
  }
  for (const children of child) {
    if (children?.child) {
      if (hasActiveChild(children, currentURL)) {
        return true;
      }
    }
    if (
      children &&
      children.route &&
      currentURL &&
      isNavLinkActive(children, currentURL)
    ) {
      return true;
    }
  }

  return false;
};

export const handleURLQueries = (router, path) => {
  // if (Object.keys(router.query).length && path) {
  //   const arr = Object.keys(router.query)

  // return router.asPath.includes(path) && router.asPath.includes(router.query[arr[0]]) && path !== '/'
  // }

  // return false
  return true;
};

export const prepareImageName = (name) => {
  const names = name?.split(".svg");
  return `${names?.[0]}_white.svg`;
};

export const scroll = (options) => {
  window.scrollTo(options);
};

export function sortByTodataDeposit(data) {
  return [...data].sort((a, b) => {
    const todataA = (a.current_turnover / a.daily_limit) * 100 || 0;
    const todataB = (b.current_turnover / b.daily_limit) * 100 || 0;

    if (todataA === 100) {
      return 1;
    } else if (todataB === 100) {
      return -1;
    }

    return todataB - todataA;
  });
}

export function sortByTodataWithdraw(data) {
  return [...data].sort((a, b) => {
    const todataA =
      (a.merchant_payout_meta?.current_turnover / a.daily_limit) * 100 || 0;
    const todataB =
      (b.merchant_payout_meta?.current_turnover / b.daily_limit) * 100 || 0;

    if (todataA === 100) {
      return 1;
    } else if (todataB === 100) {
      return -1;
    }
    return todataB - todataA;
  });
}

export const prepareBlobUrl = (svgString, type = "image/svg+xml") => {
  const blob = new Blob([svgString], { type });
  return URL.createObjectURL(blob);
};

export const prepareSvgUrl = (svg) => {
  return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
};

export const objectsAreEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const prepareIsHorizontalNavBar = (windowWidth) => {
  return !!(windowWidth >= 768);
};

export const onFixed = (value, decimal = FIXED_DECIMAL) => {
  if (!+value) {
    return 0;
  }

  return +(+value)?.toFixed(decimal);
};

export const getCurrentMonthDates = () => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const customEndDate = new Date();
  if (endOfMonth > customEndDate) {
    endOfMonth = customEndDate;
  }

  return {
    startOfMonth,
    endOfMonth,
  };
};

export function getAcronym(str) {
  return str
    .split(' ')
    .map(word => word[0])
    .join('')
    .toUpperCase();
}

export const parseJsonRecursively = (data) => {
  if (Array.isArray(data)) {
    return data?.map?.((item) => parseJsonRecursively(item));
  } else if (typeof data === "object" && data !== null) {
    const parsedObject = {};
    Object.keys?.(data || {})?.forEach?.((key) => {
      parsedObject[key] = parseJsonRecursively(data[key]);
    });
    return parsedObject;
  } else if (typeof data === "string") {
    try {
      return isParsable(data) ? /^[0-9.]+$/.test?.(data) ? data : parseJsonRecursively(JSON.parse?.(data)) : data;
    } catch (error) {
      return data;
    }
  }

  return data;
};