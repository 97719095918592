import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import InputField from "../../components/Custom/Forms/InputField/InputField";
import { Button } from "reactstrap";
import { responseToaster } from "../../helperFunctions";
import { pgUpdateUpi } from "../../redux/slices/pgSlice";
import { useRole, useUser } from "../../redux/slices/authSlice";
import { getPgIdFromPgName } from "./Pg";

const PgAccount = (props) => {
  const { data, columns, onGet, setIsLoading } = props;

  const dispatch = useDispatch();
  const locations = useLocation()?.pathname?.split("/");

  const params = { pgName: locations?.at(-1), pgType: locations?.at(-2) };
  const isPayin = params?.pgType === "payin";
  const isPayout = params?.pgType === "payout";
  const isUpiPay = (params?.pgName === "UPIPAY" && params?.pgType === "payin") || (params?.pgName === "BANKPAY" && params?.pgType === "payin");
  const payinRole = useRole("PG Deposit Bank");
  const payoutRole = useRole("PG Withdrawal Bank");
  const [isInput, setIsInput] = useState(false);
  const [value, setValue] = useState("");
  const { user } = useUser();

  const onUpdate = async () => {
    try {
      setIsLoading(true);
      const payload = {
        payload: { pk: data?.account_id, value, pg_id: getPgIdFromPgName(user?.SiderBar?.PG, params?.pgName) },
        params,
      };
      const resMin = await dispatch(pgUpdateUpi(payload)).unwrap();
      responseToaster(resMin);
      onGet();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <div className={`${data?.isOpen ? "cell-order-white" : "cell-order"}`}>
      {isPayin || isPayout ? (
        <div className="order-singal-div">
          <p onClick={(e) => e.stopPropagation()}>
            <span>Account ID : </span>
            {data?.account_id || ""}
          </p>
        </div>
      ) : null}
      {isPayin || isPayout || isUpiPay ? (
        <div className="order-singal-div">
          <p onClick={(e) => e.stopPropagation()}>
            <span>A/C No: </span>
            {data?.account_number || ""}
          </p>
        </div>
      ) : null}
      {Object.hasOwn(data, "is_seamless") && isPayin ? (
        <div className="order-singal-div">
          <p onClick={(e) => e.stopPropagation()}>
            {data?.is_seamless ? "Seamless" : "Hosted"}
          </p>
        </div>
      ) : null}
      <div className="order-singal-div">
        <p onClick={(e) => e.stopPropagation()}>
          <span>Holder : </span>
          {isPayin ? data?.label || data?.account_holder_name : data?.label}
        </p>
      </div>
      {isUpiPay ? (
        <div className="order-singal-div">
          <p onClick={(e) => e.stopPropagation()}>
            <span>IFSC : </span>
            {data?.ifsc_code}
          </p>
        </div>
      ) : null}
      {isUpiPay ? (
        <div className="order-singal-div">
          <p onClick={(e) => e.stopPropagation()}>
            <span>BANK : </span>
            {data?.bank_name}
          </p>
        </div>
      ) : null}
      {isUpiPay && data?.is_account_flow_active ? (
        <>
          <div className="bg-blue">Account Flow</div>
        </>
      ) : null}
      {isUpiPay || params?.pgName === "INTENTFLOW" ? (
        <div className="order-singal-div">
          <div>
            <span onClick={(e) => e.stopPropagation()}>UPI : </span>
            {isInput ? (
              <div
                className="d-flex gap-1"
                onClick={(e) => e.stopPropagation()}
              >
                <InputField
                  value={value}
                  placeholder="Enter Upi"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => setValue(e.target.value)}
                />
                <Button color="primary" onClick={onUpdate}>
                  <i className="fa-solid fa-check"></i>
                </Button>
                <Button
                  color="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsInput(false);
                  }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </div>
            ) : payinRole?.includes("pg-deposit-bank-update") ||
              payoutRole?.includes("pg-withdrawal-bank-update") ? (
              <span
                className="cursor-pointer"
                style={{
                  color: columns?.includes("upi_id")
                    ? "var(--chatlook-darkblue--)"
                    : "",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (columns?.includes("upi_id")) {
                    setIsInput(true);
                    setValue(data?.upi_id);
                  }
                }}
              >
                {data?.upi_id || "Not Set"}
              </span>
            ) : (
              data?.upi_id || "Not Set"
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PgAccount;
