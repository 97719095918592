import UpdateDepositeStatus from "../../../components/Custom/Modals/UpdateDepositeStatus/UpdateDepositeStatus";
import MarkAsFailedDeposite from "../../../components/Custom/Modals/MarkAsFailedDeposite/MarkAsFailedDeposite";
import BlockCustomerModal from "../../../components/Custom/Modals/BlockCustomerModal/BlockCustomerModal";
import ResendWebhookModal from "../../../components/Custom/Modals/ResendWebhookModal/ResendWebhookModal";
import UpdateTempUTRModal from "../../../components/Custom/Modals/UpdateTempUTRModal/UpdateTempUTRModal";
import RefundModal from "../../../components/Custom/Modals/RefundModal/RefundModal";
import { responseToaster, seperator } from "../../../helperFunctions";
import { getStatusStyle } from "../../../theme";
import React, { useState } from "react";
import { Button } from "reactstrap";
import TransactionRemoveFeesModal from "../../../components/Custom/Modals/TransactionRemoveFeesModal/TransactionRemoveFeesModal";
import TransactionDeleteModal from "../../../components/Custom/Modals/TransactionDeleteModal/TransactionDeleteModal";
import CustomerDetails from "./CustomerDetails";
import {
  setTransactionData,
  TransactionUpdateIsUsed,
  updateChangeBack,
  useTransaction,
} from "../../../redux/slices/transactionSlice";
import { useDispatch } from "react-redux";
import { showToaster } from "../../../constant";
import Loader from "../../../components/Custom/Loader";
import { useRole } from "../../../redux/slices/authSlice";
import ToggleSwitch from "../../../components/Custom/Forms/ToggleSwitch/ToggleSwitch";
import InputField from "../../../components/Custom/Forms/InputField/InputField";

const TransactionTableDetail = (props) => {
  const { item, onGet } = props;
  const { transactionState } = useTransaction();
  const { bgClassName } = getStatusStyle(item?.paymentSts);
  const [isMarkOpen, setIsMarkOpen] = useState("");
  const [blockCustomer, setBlockCustomer] = useState(false);
  const [isBlockCustomerId, setIsBlockCustomerId] = useState("");
  const [markAsFailed, setMarkAsFailed] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [removeFeesModal, setRemoveFeesModal] = useState(false);
  const [isMarkAsFailedId, setIsMarkAsFailedId] = useState("");
  const [resendWebhook, setResendWebhook] = useState(false);
  const [isRefundId, setIsRefundId] = useState("");
  const [isRefund, setIsRefund] = useState(false);
  const [isUpdateUtrId, setIsUpdateUtrId] = useState("");
  const [isUpdateUtr, setIsUpdateUtr] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openCusDetails, setOpenCusDetails] = useState(false);
  const [payloadTransactionData, setPayloadTransactionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUsedValue, setIsUsedValue] = useState(item?.isUsed);
  const [statusLoading, setStatusLoading] = useState(false);
  const dispatch = useDispatch();
  const role = useRole("Deposit");

  const onCloseResendModal = (id) => {
    setResendWebhook(!resendWebhook);
    if (role?.includes("deposit-resend-webhook")) {
      setIsMarkOpen(id);
    }
  };
  const onCloseBlockCustomerModal = (id) => {
    if (role?.includes("deposit-block-customer")) {
      setBlockCustomer(!blockCustomer);
    }
    setIsBlockCustomerId(id);
  };
  const onCloseMarkfailed = (id) => {
    if (role?.includes("deposit-failed")) {
      setMarkAsFailed(!markAsFailed);
    }
    setIsMarkAsFailedId(id);
  };
  const onCloseRefundOpen = (id, amount) => {
    if (role?.includes("deposit-refund")) {
      setIsRefund(!isRefund);
    }
    setIsRefundId({ ...isRefundId, txnId: id, refund_amount: amount });
  };

  const onCloseIsTempUtr = (id) => {
    if (role?.includes("deposit-update-temputr")) {
      setIsUpdateUtrId(id);
    }
    setIsUpdateUtr(!isUpdateUtr);
  };

  const toggleDeleteModal = (values) => {
    setDeleteModal(deleteModal ? false : values);
  };

  const toggleRemoveFeesModal = (values) => {
    setRemoveFeesModal(removeFeesModal ? false : values);
  };

  const handleUpdateChangeBack = async (txnId) => {
    try {
      const payload = {
        txnId,
        isChargeBack: 1,
      };
      setIsLoading(true);
      const res = await dispatch(updateChangeBack({ ...payload })).unwrap();
      if (res?.status) {
        setIsUpdate(true);
      }
      showToaster(res?.message);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const onUpdateIsUsed = async (payload) => {
  //   try {
  //     setStatusLoading(payload?.txnId);
  //     const res = await dispatch(TransactionUpdateIsUsed(payload)).unwrap();
  //     responseToaster(res);
  //     const index = transactionState?.data?.findIndex(
  //       (item) => item?.txnId === payload?.txnId
  //     );
  //     if (index > -1) {
  //       const newData = [...transactionState?.data];
  //       newData[index] = { ...newData[index], isUsed: payload?.isUsed };
  //       dispatch(
  //         setTransactionData({
  //           ...transactionState?.data,
  //           data: newData,
  //         })
  //       );
  //     }
  //     setStatusLoading(false);
  //   } catch (err) {
  //     setStatusLoading(false);
  //   }
  // };

  const handleIsUsedInput = (e) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      // Allow only single digits or empty input
      setIsUsedValue(value);
    }
  };

  const onUpdateIsUsed = async () => {
    try {
      setStatusLoading(item?.txnId);
      const payload = {
        isUsed: isUsedValue,
        txnId: item?.txnId,
      };
      const res = await dispatch(TransactionUpdateIsUsed(payload)).unwrap();

      responseToaster(res);
      if (res?.status) {
        const index = transactionState?.data?.findIndex?.(
          (txn) => txn?.txnId === payload?.txnId
        );

        if (index > -1) {
          const newData = [...transactionState?.data];
          newData[index] = { ...newData[index], isUsed: payload?.isUsed };
          dispatch(setTransactionData({
            ...transactionState?.data,
            data: newData,
          }));
          setIsUsedValue(isUsedValue)
        }
      }
      setStatusLoading(false);
    } catch (err) {
      setStatusLoading(false);
      console.error("Error updating transaction:", err);
    } finally {
      setStatusLoading(null);
    }
  };
  return (
    <>
      <div className="d-flex tabmain"></div>
      <div className="mainbox">
        <div className="d-flex justify-content-between">
          <div className="box-style">
            <h5>
              <span>Customer Details</span>
            </h5>
            <div className="other-sec">
              <p>
                <b> Name : </b> {item?.custName}
              </p>
              <p>
                <b> Email : </b> {item?.custEmail}
              </p>
              <p>
                <b> Mobile : </b> {item?.custMobile}
              </p>
              <p>
                <b> Ip : </b> {item?.custIp}
              </p>
              <p>
                <b> Device : </b> {item?.device}
              </p>
              <p>
                <b> Refer Url : </b> {item?.refererUrl}
              </p>
              <p>
                <b> Payment Data : </b> {item?.paymentData}
              </p>
            </div>
          </div>
          <div className="box-style">
            <h5>
              <span>Order Details</span>
            </h5>
            <div className="other-sec">
              <p>
                <b>MMPID :</b> {item?.bankMerchantPayId}
              </p>
              <p>
                <b>Temp UTR :</b> {item?.tempBankUtr}
              </p>
              <p>
                <b>Browser ID : </b>
                <span
                  className="cursor-pointer themecolor"
                  onClick={() => setOpenCusDetails(true)}
                >
                  {item?.browserId || "-"}
                </span>
              </p>
              <p>
                <b>Customer City :</b> {item?.custCity}
              </p>
              <p>
                <b>Udf5 :</b> {item?.udf5 || "-"}
              </p>
              {role?.includes("deposit-is-used-update") ? (
                <div className="d-flex align-items-end mt-1 button-space tableinput">
                  <InputField
                    label="Is Used"
                    value={isUsedValue}
                    disabled={statusLoading === item?.txnId}
                    placeholder="Enter Number"
                    type="number"
                    onChange={handleIsUsedInput}
                  />
                  <Button
                    color="primary"
                    className="btn-smallsize"
                    onClick={() => {
                      onUpdateIsUsed();
                    }}
                    disabled={statusLoading === item?.txnId}
                  >
                    <i className="fa-solid fa-check"></i>
                  </Button>
                </div>
              ) : null}

              {/* {role?.includes("deposit-is-used-update") ? (
                <p>
                  <b>Is Used :</b>
                  <ToggleSwitch
                    isLoading={statusLoading === item?.txnId}
                    value={item?.isUsed ? 1 : 0}
                    className={`${statusLoading ? "disabled" : ""}`}
                    onChange={async (value, e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      try {
                        const payload = {
                          isUsed: value,
                          txnId: item?.txnId,
                        };
                        await onUpdateIsUsed(payload);
                      } catch (err) {}
                    }}
                  />
                </p>
              ) : null} */}
            </div>
          </div>
          <div className="box-style">
            <h5>
              <span>Client Details</span>
            </h5>
            <div className="other-sec">
              <p>
                <b>Name :</b> {item?.merchantDatas?.merchantName}
              </p>
              <p>
                <b>Asso.Fee :</b> {seperator(item?.associateFees)}
              </p>
              <p>
                <b>PG Ref:</b> {item?.pgRefId}
              </p>
            </div>
          </div>
          <div className="box-style">
            <h5>
              <span> PG Details</span>
            </h5>
            <div className="other-sec">
              <p>
                <b>Account : </b> {item?.acNumber || "-"}
              </p>
              <p>
                <b>UPI :</b> {item?.upiId || "-"}
              </p>
              <p>
                <b>Bank :</b> {item?.bankName || "-"}
              </p>
              <p>
                <b>IFSC : </b> {item?.ifscCode || "-"}
              </p>
              <p>
                <b>UPI NAME : </b> {item?.pgBankUpiName || "-"}
              </p>
              <p>
                <b>UPI ID : </b> {item?.pgBankUpiId || "-"}
              </p>
              <p>
                <b>BANK ID : </b> {item?.bankId || "-"}
              </p>
            </div>
          </div>
          <div className="box-style d-flex flex-column justify-content-between">
            <div>
              <h5>
                <span> Player Details</span>
              </h5>
              <div className="other-sec">
                <p>
                  <b>Deposit Amount : </b> {item?.playerDepositAmt || "-"}
                </p>
                <p>
                  <b>Deposit Count :</b> {item?.playerDepositCount || "-"}
                </p>
                <p>
                  <b>Register Date :</b> {item?.playerRegisterDate || "-"}
                </p>
              </div>
            </div>
            <div>
              {item?.processBy ? (
                <div className="bgDiv">Update By : {item?.processBy}</div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="amount-sec">
          <div className="amount-sub">
            <div>
              <p>Total Amount</p>
              <h4>{`${seperator(+item?.paymentAmt)}`}</h4>
            </div>
            <div>
              <p>Order ID</p>
              <h6> {item?.merchantOrderId}</h6>
            </div>
            <div>
              <p>UTR</p>
              <h6> {item?.utr}</h6>
            </div>
            <div>
              <p>Status</p>
              <span className={`${bgClassName}`}>{item?.paymentSts}</span>
            </div>
            <div className="d-flex gap-1">
              {role.includes("deposit-is-charge-back") ? (
                <Button
                  className="tablebtn btn-smallsize"
                  onClick={() => handleUpdateChangeBack(item?.txnId)}
                  disabled={item?.isChargeBack || isUpdate}
                >
                  {isLoading ? <Loader size={20} /> : "Chargeback"}
                </Button>
              ) : null}
              {item?.paymentSts !== "Success" &&
                role.includes("deposit-status-update") && (
                  <Button
                    className="tablebtn btn-smallsize"
                    onClick={() => {
                      setIsOpen(true);
                      setPayloadTransactionData({
                        depositId: item?.txnId,
                        depositSts: null,
                        utr: item?.utr,
                      });
                    }}
                  >
                    Update
                  </Button>
                )}
              {item?.paymentSts === "Success" ||
              (item?.paymentSts === "Failed" &&
                role?.includes("deposit-resend-webhook")) ? (
                <>
                  <Button
                    className="tablebtn btn-smallsize"
                    onClick={() => onCloseResendModal(item?.txnId)}
                  >
                    Resend Webhook
                  </Button>
                </>
              ) : null}
              {item?.paymentSts !== "Success" ? (
                <>
                  {role?.includes("deposit-update-temputr") ? (
                    <Button
                      className="tablebtn btn-smallsize"
                      onClick={() => onCloseIsTempUtr(item?.txnId)}
                    >
                      Update New TempUtr
                    </Button>
                  ) : null}
                  {role?.includes("deposit-failed") ? (
                    <Button
                      className="tablebtn btn-smallsize"
                      onClick={() => onCloseMarkfailed(item?.txnId)}
                    >
                      Mark as Failed
                    </Button>
                  ) : null}
                </>
              ) : null}
              {role?.includes("deposit-block-customer") ? (
                <Button
                  className="tablebtn btn-smallsize"
                  disabled={item?.isBlockedCust}
                  onClick={() => onCloseBlockCustomerModal(item?.txnId)}
                >
                  {item?.isBlockedCust ? "Blocked Customer" : "Block Customer"}
                </Button>
              ) : null}
              {item?.paymentSts == "Success" &&
              role?.includes("deposit-refund") ? (
                <>
                  <Button
                    className="tablebtn btn-smallsize"
                    onClick={() =>
                      onCloseRefundOpen(item?.txnId, item?.paymentAmt)
                    }
                  >
                    Refund
                  </Button>
                </>
              ) : null}
              {item?.bankId === null && item?.paymentSts == "Success" ? (
                <>
                  {role?.includes("deposit-delete") ? (
                    <Button
                      className="tablebtn btn-smallsize"
                      onClick={() => toggleDeleteModal(item)}
                    >
                      Delete
                    </Button>
                  ) : null}
                  {role?.includes("deposit-remove-fees") ? (
                    <Button
                      className="tablebtn btn-smallsize"
                      onClick={() => toggleRemoveFeesModal(item)}
                    >
                      Remove Fees
                    </Button>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <UpdateDepositeStatus
        isOpen={isOpen}
        bankutr={payloadTransactionData}
        onCloseUpdateDepositeStatus={() => setIsOpen(false)}
        onGet={onGet}
      />
      <BlockCustomerModal
        blockCustomer={blockCustomer}
        isBlockCustomerId={isBlockCustomerId}
        onCloseBlockCustomerModal={onCloseBlockCustomerModal}
        onGet={onGet}
      />
      <ResendWebhookModal
        isMarkOpen={isMarkOpen}
        resendWebhook={resendWebhook}
        onCloseResendModal={onCloseResendModal}
        onGet={onGet}
      />
      <TransactionDeleteModal
        isOpen={deleteModal}
        onHide={toggleDeleteModal}
        onGet={onGet}
      />
      <TransactionRemoveFeesModal
        isOpen={removeFeesModal}
        onHide={toggleRemoveFeesModal}
        onGet={onGet}
      />
      <RefundModal
        isRefundId={isRefundId}
        isRefund={isRefund}
        onCloseRefundOpen={onCloseRefundOpen}
        onGet={onGet}
      />
      <UpdateTempUTRModal
        isUpdateUtrId={isUpdateUtrId}
        isUpdateUtr={isUpdateUtr}
        onCloseIsTempUtr={onCloseIsTempUtr}
        onGet={onGet}
      />
      <MarkAsFailedDeposite
        markAsFailed={markAsFailed}
        isMarkAsFailedId={isMarkAsFailedId}
        onCloseMarkfailed={onCloseMarkfailed}
        onGet={onGet}
      />
      <CustomerDetails
        isOpen={openCusDetails}
        payload={item?.browserId}
        onHide={() => setOpenCusDetails(false)}
      />
    </>
  );
};

export default TransactionTableDetail;
