import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Block_Customer,
  Get_Customers_Details,
  Get_Deposite_Status_Update,
  Get_Generated_Report,
  Get_Payment_Meta_Label_List,
  Mark_As_Failed_Deposite,
  Transaction,
  Transaction_Filter_Data,
  Transaction_Model,
  Transaction_Refund,
  Transaction_Resend_Webhook,
  Transaction_Summary,
  Transaction_Update_Is_Used,
  Transaction_Update_Temputr,
  block_customer_byHId,
  transactionDelete,
  transactionRemoveFees,
  update_charge_back,
} from "../services/transactionServices";
const initialState = {
  filter: false,
  transactionState: [],
  transactionFilterState: [],
  summaryTransactionState: [],
  transactionStateisLoading: false,
  depositeStateisLoading: true,
  filterStateisLoading: false,
  paymentMetaLabelListGetData: [],
  DepositeStatusUpdateState: [],
  customersDetailsState: [],
  customersDetailsisLoading: true,
};
export const getTransaction = createAsyncThunk(
  "/transaction",
  async (payload) => {
    return await Transaction(payload);
  }
);
export const Filter_Data_Transaction = createAsyncThunk(
  "/filtertransaction",
  async (payload) => {
    return await Transaction_Filter_Data(payload);
  }
);
export const Generated_Report_Get = createAsyncThunk(
  "/GetGeneratedReport",
  async (payload) => {
    return await Get_Generated_Report(payload);
  }
);
export const Summary_Transaction = createAsyncThunk(
  "/Transaction_Summary",
  async (payload) => {
    return await Transaction_Summary(payload);
  }
);
export const Resend_Webhook_Transaction = createAsyncThunk(
  "/Transaction_Resend_Webhook",
  async (payload) => {
    return await Transaction_Resend_Webhook(payload);
  }
);
export const removeFeesTransaction = createAsyncThunk(
  "/removeFeesTransaction",
  async (payload) => {
    return await transactionRemoveFees(payload);
  }
);
export const deleteTransaction = createAsyncThunk(
  "/deleteTransaction",
  async (payload) => {
    return await transactionDelete(payload);
  }
);
export const Customer_Block = createAsyncThunk(
  "/Block_Customer",
  async (payload) => {
    return await Block_Customer(payload);
  }
);
export const MarkAs_Failed_Deposite = createAsyncThunk(
  "/MarkAs_Failed_Deposite",
  async (payload) => {
    return await Mark_As_Failed_Deposite(payload);
  }
);
export const Refund_Transaction = createAsyncThunk(
  "/Transaction_Refund",
  async (payload) => {
    return await Transaction_Refund(payload);
  }
);
export const Update_Temputr_Transaction = createAsyncThunk(
  "/Transaction_Update_Temputr",
  async (payload) => {
    return await Transaction_Update_Temputr(payload);
  }
);
export const GetTransactionById = createAsyncThunk(
  "/transactionbyUtr",
  async (payload) => {
    return await Transaction_Model(payload);
  }
);
export const PaymentMetaLabelListGet = createAsyncThunk(
  "/PaymentMetaLabelListGet",
  async (payload) => {
    return await Get_Payment_Meta_Label_List(payload);
  }
);
export const DepositeStatusUpdate = createAsyncThunk(
  "/DepositeStatusUpdate",
  async (payload) => {
    return await Get_Deposite_Status_Update(payload);
  }
);
export const GetCustomersDetails = createAsyncThunk(
  "/transactionbyBrowserId",
  async (payload) => {
    return await Get_Customers_Details(payload);
  }
);
export const blockCustomerByHId = createAsyncThunk(
  "/block-customer-byHId",
  async (payload) => {
    return await block_customer_byHId(payload);
  }
);
export const updateChangeBack = createAsyncThunk(
  "/ChangeBack",
  async (payload) => {
    return await update_charge_back(payload);
  }
);
export const TransactionUpdateIsUsed = createAsyncThunk(
  "/ChangeBack",
  async (payload) => {
    return await Transaction_Update_Is_Used(payload);
  }
);

const transactionSlice = createSlice({
  name: "transactionSlice",
  initialState,
  reducers: {
    setTransactionFilter: (state, { payload }) => {
      state.filter = payload;
    },
    setTransactionData: (state, { payload }) => {
      state.transactionState = payload;
    },
    clearTransaction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTransaction.pending, (state, action) => {
      state.transactionStateisLoading = true;
    });
    builder.addCase(getTransaction.fulfilled, (state, action) => {
      state.transactionStateisLoading = false;
      state.transactionState = action.payload;
    });
    builder.addCase(getTransaction.rejected, (state, action) => {
      state.transactionStateisLoading = false;
    });
    builder.addCase(GetCustomersDetails.pending, (state, action) => {
      state.customersDetailsisLoading = true;
    });
    builder.addCase(GetCustomersDetails.fulfilled, (state, action) => {
      state.customersDetailsisLoading = false;
      state.customersDetailsState = action.payload;
    });
    builder.addCase(GetCustomersDetails.rejected, (state, action) => {
      state.customersDetailsisLoading = false;
    });
    builder.addCase(DepositeStatusUpdate.fulfilled, (state, action) => {
      state.depositeStateisLoading = false;
      state.DepositeStatusUpdateState = action.payload;
    });
    builder.addCase(DepositeStatusUpdate.pending, (state, action) => {
      state.depositeStateisLoading = true;
    });
    builder.addCase(DepositeStatusUpdate.rejected, (state, action) => {
      state.depositeStateisLoading = false;
    });
    builder.addCase(Filter_Data_Transaction.pending, (state, action) => {
      state.filterStateisLoading = true;
    });
    builder.addCase(Filter_Data_Transaction.fulfilled, (state, action) => {
      state.filterStateisLoading = false;
      state.transactionFilterState = action.payload;
    });
    builder.addCase(Filter_Data_Transaction.rejected, (state, action) => {
      state.filterStateisLoading = false;
    });
    builder.addCase(Summary_Transaction.fulfilled, (state, action) => {
      state.summaryTransactionState = action.payload;
    });
    builder.addCase(PaymentMetaLabelListGet.fulfilled, (state, action) => {
      state.paymentMetaLabelListGetData = action.payload;
    });
  },
});

export default transactionSlice.reducer;
export const { setTransactionFilter, clearTransaction, setTransactionData } =
  transactionSlice.actions;

export const selectTransaction = (state) => {
  return state.transaction.transactionState;
};
export const useTransaction = () => {
  const transactionState = useSelector(selectTransaction);
  return useMemo(() => ({ transactionState }), [transactionState]);
};

export const selectTransactionFilter = (state) => {
  return state.transaction.filter;
};
export const useTransactionFilter = () => {
  const filter = useSelector(selectTransactionFilter);
  return useMemo(() => filter, [filter]);
};
