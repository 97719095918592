import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { logIn } from "../services/authServices";
import {
  Cancelled_Initialized_Payout,
  Get_Payout_Configuration,
  Payout,
  Payout_Status_Update,
  Payout_order_id,
  Resend_Webhook,
  Reset_Initialized_Payout,
  Reset_LowBal_Payout_To_Initialize,
  Reset_LowBal_Payout_To_Initialize_Ids,
  Reset_Recheck,
  Update_Payout_Configuration,
} from "../services/payoutServices";
import { getLocalData } from "../../helperFunctions";

const initialState = {
  payoutState: [],
  payoutStateisLoading: true,
  patoutOrderIdState: [],
  payoutOrderIdStateLoading: true,
  viewpayoutorderidState: [],
  payoutConfigurationGetState: [],
  storedTimeouts: getLocalData("resetBlc")
};
export const getPayout = createAsyncThunk("/payout", async (payload) => {
  return await Payout(payload);
});
export const Status_Update_Payout = createAsyncThunk(
  "/status_update_payout",
  async (payload) => {
    return await Payout_Status_Update(payload);
  }
);
export const GetPayoutById = createAsyncThunk(
  "/GetPayoutById",
  async (payload) => {
    return await Payout_order_id(payload);
  }
);
export const Webhook_Resend = createAsyncThunk(
  "/ResendWebhook",
  async (payload) => {
    return await Resend_Webhook(payload);
  }
);
export const Initialized_Payout_Cancelled = createAsyncThunk(
  "/Cancelled_Initialized_Payout",
  async (payload) => {
    return await Cancelled_Initialized_Payout(payload);
  }
);
export const Initialized_Payout_Reset = createAsyncThunk(
  "/Reset_Initialized_Payout",
  async (payload) => {
    return await Reset_Initialized_Payout(payload);
  }
);
export const Payout_Configuration_Get = createAsyncThunk(
  "/Get_Payout_Configuration",
  async () => {
    return await Get_Payout_Configuration();
  }
);
export const Payout_Configuration_Update = createAsyncThunk(
  "/Update_Payout_Configuration",
  async (payload) => {
    return await Update_Payout_Configuration(payload);
  }
);
export const LowBal_Payout_To_Initialize_Reset = createAsyncThunk(
  "/Reset_LowBal_Payout_To_Initialize",
  async (payload) => {
    return await Reset_LowBal_Payout_To_Initialize(payload);
  }
);
export const LowBal_Payout_To_Initialize_Ids_Reset = createAsyncThunk(
  "/Reset_LowBal_Payout_To_Initialize",
  async (payload) => {
    return await Reset_LowBal_Payout_To_Initialize_Ids(payload);
  }
);
export const Recheck_Reset = createAsyncThunk(
  "/Reset_Recheck",
  async (payload) => {
    return await Reset_Recheck(payload);
  }
);

const payoutSlice = createSlice({
  name: "payoutSlice",
  initialState,
  reducers: {
    clearPayout: () => initialState,
    setStoredTimeouts: (state, { payload }) => {
      state.storedTimeouts = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPayout.fulfilled, (state, action) => {
      state.payoutStateisLoading = false;
      state.payoutState = action.payload;
    });
    builder.addCase(getPayout.pending, (state, action) => {
      state.payoutStateisLoading = true;
    });
    builder.addCase(getPayout.rejected, (state, action) => {
      state.payoutStateisLoading = false;
    });
    builder.addCase(Payout_Configuration_Get.fulfilled, (state, action) => {
      state.payoutConfigurationGetState = action.payload;
    });
  },
});
export default payoutSlice.reducer;
export const { clearPayout, setStoredTimeouts } = payoutSlice.actions;

export const selectPayoutOrderIdState = (state) =>
  state.payout.viewpayoutorderidState;

export const usePayoutOrderState = () => {
  const viewpayoutorderidState = useSelector(selectPayoutOrderIdState);
  return useMemo(() => viewpayoutorderidState, [viewpayoutorderidState]);
};

export const selectStoredTimeouts = (state) =>
  state.payout.storedTimeouts;

export const useStoredTimeoutsState = () => {
  const storedTimeouts = useSelector(selectStoredTimeouts);
  return useMemo(() => storedTimeouts, [storedTimeouts]);
};
