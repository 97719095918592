import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Modal } from "reactstrap";
import { userAdd } from "../../../redux/slices/smsSlice";
import { showToaster } from "../../../constant";
import { useDispatch } from "react-redux";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { useRole } from "../../../redux/slices/authSlice";
import EyeOff from "../../../assets/images/pages/eye-off.svg";
import EyeOn from "../../../assets/images/pages/eye-on.svg";

const UserModal = (props) => {
  const { isOpen, data, onClose, getUserData } = props;
  const [isCopy, setIsCopy] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const dispatch = useDispatch();

  const role = useRole("Sms Sync");

  const schema = yup.object({
    userName: yup.string().required("Please enter user name"),
    password: yup.string().required("Please enter password"),
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const handleButtonClick = async () => {
    try {
      setisLoading(true);
      const values = getValues();
      let payload = {
        ...values,
      };
      const res = await dispatch(userAdd({
        ...(data && data?.id && { userId: data?.id }),
        ...payload,
      })).unwrap();

      if (res?.status) {
        showToaster(res?.message);
        getUserData();
        onClose();
        reset()
      }
      setisLoading(false);
    } catch (err) {
      setisLoading(false);
      console.error("Error:", err);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        reset();
        setIsCopy(null);
        onClose();
        setisLoading(false);
      }}
      centered={true}
      className={`modal-md`}
    >
      <div className="modal-header">
        <h3 className="modal-title mt-0">{`${!!!isCopy ? `${Object.keys(data)?.length ? "Update" : "Add"} User` : "User's Credential"}`}</h3>
        <i
          onClick={() => {
            reset();
            setIsCopy(null);
            onClose();
            setisLoading(false);
          }}
          className="fa-solid fa-xmark"
        ></i>
      </div>
      <div className="modal-body">
          <form onSubmit={handleSubmit(handleButtonClick)}>
            <div className="row">
              <div className="col-12">
                <ControlledInput
                  className="w-full"
                  name="userName"
                  placeholder="Enter User Name"
                  label="User Name"
                  autoComplete="new-password"
                  control={control}
                  onChange={(e) => {
                    if (/^\S*$/.test(e.target.value)) {
                      setValue("userName", e.target.value);
                    }
                    trigger("userName")
                  }}
                  errors={errors}
                />
                <ControlledInput
                  type={isShow ? "text" : "password"}
                  name="password"
                  autoComplete="new-password"
                  label="Password"
                  className="w-100 mb-50"
                  inputClassName="py-50"
                  placeholder="Enter your password"
                  rightIcon={
                    <img
                      src={isShow ? EyeOn : EyeOff}
                      alt="Eye"
                      onClick={() => setIsShow(!isShow)}
                    />
                  }
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="flex justify-end items-center gap-2 !pt-4">
              <div className="d-flex justify-content-end w-full items-center pt-2">
                <div className="d-flex justify-content-end button-space">
                  <Button type="button" className="graybutton" onClick={() => {
                    reset();
                    setIsCopy(null);
                    onClose();
                    setisLoading(false);
                  }}>
                    Cancel
                  </Button>
                  {role.includes("add-sms-user") || role.includes("update-sms-user") ? 
                  <CustomeButton className="submitbutton" type="submit" isButtonLoading={isLoading}>
                    {data?.id ? role.includes("update-sms-user") ? "Update" : null : role.includes("add-sms-user") ? "Submit" : null}
                  </CustomeButton> : null}
                </div>
              </div>

            </div>
          </form>
      </div>

    </Modal>


  );
};

export default UserModal;
