import {
  CANCELLED_INITIALIZED,
  GET_PAYOUT_CONFIGURATION,
  PAYOUT,
  PAYOUT_ORDER_ID,
  PAYOUT_STATUS_UPDATE,
  RESEND_WEBHOOK,
  RESET_INITIALIZED_PAYOUT,
  RESET_LOWBAL_PAYOUT_TO_INITIALTIZE,
  RESET_LOWBAL_PAYOUT_TO_INITIALTIZE_IDS,
  RESET_RECHECK,
  UPDATE_PAYOUT_CONFIGURATION,
} from "../api/constApi";
import { apiInstance } from "./axiosApi";

export const Payout = (payload) => {
  return apiInstance.post(PAYOUT, payload);
};

export const Payout_Status_Update = (payload) => {
  return apiInstance.post(PAYOUT_STATUS_UPDATE, payload);
};
export const Payout_order_id = (payload) => {
  return apiInstance.post(PAYOUT_ORDER_ID, payload);
};
export const Resend_Webhook = (payload) => {
  return apiInstance.post(RESEND_WEBHOOK, payload);
};
export const Cancelled_Initialized_Payout = (payload) => {
  return apiInstance.post(CANCELLED_INITIALIZED, payload);
};
export const Reset_Initialized_Payout = (payload) => {
  return apiInstance.post(RESET_INITIALIZED_PAYOUT, payload);
};
export const Get_Payout_Configuration = () => {
  return apiInstance.post(GET_PAYOUT_CONFIGURATION);
};
export const Update_Payout_Configuration = (payload) => {
  return apiInstance.post(UPDATE_PAYOUT_CONFIGURATION, payload);
};
export const Reset_LowBal_Payout_To_Initialize = (payload) => {
  return apiInstance.post(RESET_LOWBAL_PAYOUT_TO_INITIALTIZE, payload);
};
export const Reset_LowBal_Payout_To_Initialize_Ids = (payload) => {
  return apiInstance.post(RESET_LOWBAL_PAYOUT_TO_INITIALTIZE_IDS, payload);
};
export const Reset_Recheck = (payload) => {
  return apiInstance.post(RESET_RECHECK, payload);
};
