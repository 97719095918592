import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { webhookStatus } from "../services/webhookStatusService";

const initialState = {
  webhookStatusStats: [],
  isLoading: true,
};
export const getWebhookStatus = createAsyncThunk(
  "/support/GetWebhookStatus",
  async (payload) => {
    return await webhookStatus(payload);
  }
);

const webhookStatusSlice = createSlice({
  name: "webhookStatusSlice",
  initialState,
  reducers: {
    clearWebhook: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWebhookStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getWebhookStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.webhookStatusStats = action.payload;
    });
    builder.addCase(getWebhookStatus.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default webhookStatusSlice.reducer;
export const { clearWebhook } = webhookStatusSlice.actions;

export const selectWebhook = (state) => {
  return state?.webHookStatus?.webhookStatusStats;
};

export const useWebhookStatus = () => {
  const webhookStatusStats = useSelector(selectWebhook);
  return useMemo(() => (webhookStatusStats), [webhookStatusStats]);
};
