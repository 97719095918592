import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Modal } from "reactstrap";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useSelector } from "react-redux";
import { getLocalData } from "../../../../helperFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ControlledDropDown from "../../Forms/Controller/ControlledDropDown";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import {
  statusDataOptions,
  unitOptions,
} from "../../../../pages/Payin_Payout/Transaction/mock";
import axios from "axios";

const StatusCheckModal = (props) => {
  const { isModalOpen, onCloseModal, onGet } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { statusCheckState } = useSelector((state) => state.statusCheck);
  const schema = Yup.object({
    status: Yup.string().required("Please select status."),
    unit: Yup.string().required("Please select unit."),
    pg_name: Yup.string().required("Please select pg."),
    interval: Yup.string().required("Please enter interval."),
  });

  const initialValues = {
    status: "",
    unit: "",
    pg_name: "",
    interval: "",
  };

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const onClose = () => {
    onCloseModal();
    reset();
  };

  const onSubmit = async () => {
    try {
      const values = getValues();
      const b = getLocalData("b");
      setIsLoading(true);
      const payload = {
        ...values,
        browser_id: b?.visitorId || "",
      };
      const res = await axios.get(
        "https://pay2.pluspedirect.com/status/check",
        {
          params: payload,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res?.data?.status) {
        onClose();
        await onGet();
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const preparePgOption = () => {
    const options = statusCheckState?.pgDetail
      ?.slice()
      ?.sort((a, b) => a?.localeCompare(b))
      ?.map((val) => {
        const clientDataOption = {
          value: val,
          label: val,
        };
        return clientDataOption;
      });
    return options;
  };

  return (
    <Modal isOpen={isModalOpen} toggle={onClose} centered={true}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Status Check</h3>
          <i onClick={onClose} className="fa-solid fa-xmark"></i>
        </div>
        <div className="modal-body">
          <ControlledDropDown
            id="status"
            name="status"
            label="Status"
            errors={errors}
            options={statusDataOptions}
            control={control}
          />
          <ControlledDropDown
            id="unit"
            name="unit"
            label="Unit"
            errors={errors}
            options={unitOptions}
            control={control}
          />
          <ControlledInput
            name="interval"
            label="Interval"
            errors={errors}
            type="number"
            placeholder="Enter Interval"
            control={control}
          />
          <ControlledDropDown
            id="pg_name"
            name="pg_name"
            label="Pg"
            errors={errors}
            options={preparePgOption()}
            control={control}
          />
        </div>
        <div className="modal-footer">
          <div className="d-flex justify-content-end button-space">
            <Button type="button" className="graybutton" onClick={onClose}>
              Cancel
            </Button>
            <CustomeButton
              type="submit"
              isButtonLoading={isLoading}
              className="submitbutton"
            >
              Submit
            </CustomeButton>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default StatusCheckModal;
