import React, { useState } from "react";
import { Form, Modal } from "reactstrap";
import "./ViewResDataModal.scss";
import { useEffect } from "react";
import { isParsable, parseJsonRecursively } from "../../../../helperFunctions";

const ViewResDataModal = ({ isViewsendDataModal, onCloseModal, data }) => {
  const [parsedData1, setParsedData1] = useState(null);

  useEffect(() => {
    let parsedRequestBody = null;
    try {
      parsedRequestBody = isParsable?.(data?.pgRes)
        ? parseJsonRecursively(JSON.parse?.(data?.pgRes))
        : data?.pgRes;
    } catch (error) {
      console.error("Error parsing pgRes:", error);
    }
    setParsedData1(parsedRequestBody);

  }, [data?.pgRes]);

  return (
    <Form>
      <Modal
        isOpen={!!isViewsendDataModal}
        toggle={() => onCloseModal()}
        className="view-send-data-modal modal-lg"
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-0">View Response Data</h3>
          <i
            onClick={() => {
              onCloseModal();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body w-100 json-style">
          <h5>Webhook Status Response Data</h5>
          <div className="webevent-sec mb-1 req_text_wrap requestContainer">
            <pre>{parsedData1 ? JSON.stringify?.(parsedData1, null, 2) : "No request body"}</pre>
          </div>

        </div>
      </Modal>
    </Form>
  );
};

export default ViewResDataModal;
