import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { getStatusCheck } from "../services/statusCheckServices";

const initialState = {
  statusCheckState: [],
  statusCheckIsLoading: false,
};


export const statusCheckGet = createAsyncThunk(
  "getStatusCheck",
  async (payload) => {
    return await getStatusCheck(payload);
  }
);

const statusCheckSlice = createSlice({
  name: "statusCheckSlice",
  initialState,
  reducers: {
    clearStatusCheck: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(statusCheckGet.pending, (state, action) => {
      state.statusCheckIsLoading = true;
    });
    builder.addCase(statusCheckGet.fulfilled, (state, action) => {
      state.statusCheckIsLoading = false;
      state.statusCheckState = action.payload;
    });
    builder.addCase(statusCheckGet.rejected, (state, action) => {
      state.statusCheckIsLoading = false;
    });
  },
});
export default statusCheckSlice.reducer;
export const { clearStatusCheck, statusCheckIsLoading } = statusCheckSlice.actions;

export const selectStatusCheckState = (state) =>
  state.statusCheck.statusCheckState;

export const useStatusCheckState = () => {
  const statusCheckState = useSelector(selectStatusCheckState);
  return useMemo(() => statusCheckState, [statusCheckState]);
};

