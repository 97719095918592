import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVpaVerify } from "../services/vpaVerifyServices";

const initialState = {
  data: {},
};
export const vpaVerifyGet = createAsyncThunk(
  "vpaVerifyGet",
  async (payload) => {
    return await getVpaVerify(payload);
  }
);
const vpaVerifySlice = createSlice({
  name: "vpaVerifys",
  initialState,
  reducers: {
    clearVpaVerify: () => initialState,
  },
  extraReducers: (builder) => {},
});

export default vpaVerifySlice.reducer;
export const {clearVpaVerify } =
  vpaVerifySlice.actions;
