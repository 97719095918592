import React, { useCallback, useState } from "react";
import { Button, Form, Modal } from "reactstrap";
import { Switch } from "@mui/material";
import {
  LowBal_Payout_To_Initialize_Ids_Reset,
  LowBal_Payout_To_Initialize_Reset,
  Payout_Configuration_Get,
  Payout_Configuration_Update,
  Recheck_Reset,
  setStoredTimeouts,
  useStoredTimeoutsState,
} from "../../../../redux/slices/payoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getLocalData, responseToaster } from "../../../../helperFunctions";
import CustomeButton from "../../Forms/CustomeButton/CustomeButton";
import { useForm } from "react-hook-form";
import ControlledInput from "../../Forms/Controller/ControlledInput";
import { timeoutForPayout } from "../../../../pages/Payin_Payout/Payout/mock";

const ConfigurationModal = (props) => {
  const { onCloseIsConfigurastionModal, isConfigurastionModal, onGet } = props;
  const dispatch = useDispatch();
  const { payoutConfigurationGetState } = useSelector((state) => state.payout);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isGetLoading, setIsGetLoading] = useState(false);
  const storedTimeouts = useStoredTimeoutsState()

  const getLocalDataa = useCallback((key) => {
    return JSON.parse(localStorage.getItem(key)) ?? {};
  }, []);
  useEffect(() => {
    if (isConfigurastionModal) {
      const resetData = getLocalDataa("resetBlc");
      setStoredTimeouts(resetData);
    }
  }, [getLocalDataa, isConfigurastionModal]);

  const initialValues = {
    autoTransferAllow: 0,
    highAutoShiftLimit: 0,
    highFinalFailedLimit: 0,
    highLowbalLimit: 0,
    highManualShiftCountLimit: 0,
    highManualShiftLimit: 0,
    highWaitingLimit: 0,
    isAutoLevelAlive: 0,
    isWithdrawalStsCallAllow: 0,
    largeFirst: 0,
    lowAutoShiftLimit: 0,
    lowManualShiftLimit: 0,
    smallFirst: 0,
    withdrawaldelayedInSeconds: 0,
    isCentralActive: 0,
  };

  useEffect(() => {
    if (payoutConfigurationGetState?.data) {
      setValue(
        "autoTransferAllow",
        !!payoutConfigurationGetState?.data?.autoTransferAllow ? 1 : 0
      );
      setValue(
        "highAutoShiftLimit",
        payoutConfigurationGetState?.data?.highAutoShiftLimit || 0
      );
      setValue(
        "highFinalFailedLimit",
        payoutConfigurationGetState?.data?.highFinalFailedLimit || 0
      );
      setValue(
        "highLowbalLimit",
        payoutConfigurationGetState?.data?.highLowbalLimit || 0
      );
      setValue(
        "highManualShiftCountLimit",
        payoutConfigurationGetState?.data?.highManualShiftCountLimit || 0
      );
      setValue(
        "highManualShiftLimit",
        payoutConfigurationGetState?.data?.highManualShiftLimit || 0
      );
      setValue(
        "highWaitingLimit",
        payoutConfigurationGetState?.data?.highWaitingLimit || 0
      );
      setValue(
        "isAutoLevelAlive",
        !!payoutConfigurationGetState?.data?.isAutoLevelAlive ? 1 : 0
      );
      setValue(
        "isWithdrawalStsCallAllow",
        payoutConfigurationGetState?.data?.isWithdrawalStsCallAllow || 0
      );
      setValue(
        "largeFirst",
        payoutConfigurationGetState?.data?.largeFirst || 0
      );
      setValue(
        "lowAutoShiftLimit",
        payoutConfigurationGetState?.data?.lowAutoShiftLimit || 0
      );
      setValue(
        "lowManualShiftLimit",
        payoutConfigurationGetState?.data?.lowManualShiftLimit || 0
      );
      setValue(
        "smallFirst",
        payoutConfigurationGetState?.data?.smallFirst || 0
      );
      setValue(
        "isCentralActive",
        payoutConfigurationGetState?.data?.isCentralActive || 0
      );
      setValue(
        "withdrawaldelayedInSeconds",
        payoutConfigurationGetState?.data?.withdrawaldelayedInSeconds || 0
      );
    }
  }, [payoutConfigurationGetState]);

  const onSubmit = async () => {
    try {
      const values = getValues();
      setIsButtonLoading(true);
      let payload = {
        ...values,
      };
      const res = await dispatch(Payout_Configuration_Update(payload)).unwrap();
      responseToaster(res);
      GetPayoutConfiguration();
      onClose();
      onGet();
      setIsButtonLoading(false);
    } catch (err) {
      setIsButtonLoading(false);
    }
  };

  const onClose = () => {
    onCloseIsConfigurastionModal();
    reset();
  };

  const { control, getValues, watch, setValue, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
  });

  const GetPayoutConfiguration = async () => {
    try {
      setIsGetLoading(true)
      await dispatch(Payout_Configuration_Get()).unwrap();
      setIsGetLoading(false)
    } catch (err) {
      setIsGetLoading(false)
    }
  };

  const Recheck_reset = async () => {
    try {
      const res = await dispatch(Recheck_Reset()).unwrap();
      responseToaster(res);
    } catch (err) { }
  };
  const LowBalPayoutToInitializeReset = async () => {
    try {
      // setTimeout(async () => {
      //   const res = await dispatch(LowBal_Payout_To_Initialize_Reset()).unwrap();
      //   if (res) {
      //     setStoredTimeouts({})
      //     const updatedTimeouts =
      //       JSON.parse(localStorage.getItem("resetBlc")) || {};
      //     delete updatedTimeouts[1];
      //     localStorage.setItem("resetBlc", JSON.stringify(updatedTimeouts));
      //   }
      //   responseToaster(res);
      // }, timeoutForPayout
      // )
      setLoading(true)
      const res = await dispatch(LowBal_Payout_To_Initialize_Reset()).unwrap();
      if (res) {
        responseToaster(res);
        const timeoutDuration = timeoutForPayout;
        const timeoutExpiry = Date.now() + timeoutDuration;

        const storedTimeouts =
          JSON.parse(localStorage.getItem("resetBlc")) || {};
        storedTimeouts[1] = timeoutExpiry;
        localStorage.setItem("resetBlc", JSON.stringify(storedTimeouts));
        dispatch(setStoredTimeouts(storedTimeouts))
        // Set timeout to re-enable the button after 10 minutes
        setTimeout(async () => {
          try {
            const res = await dispatch(LowBal_Payout_To_Initialize_Ids_Reset()).unwrap();
            responseToaster(res);
          } catch (error) {}
            const updatedTimeouts =
              JSON.parse(localStorage.getItem("resetBlc")) || {};
            delete updatedTimeouts[1];
            localStorage.setItem("resetBlc", JSON.stringify(updatedTimeouts));
            setStoredTimeouts(updatedTimeouts);
            dispatch(setStoredTimeouts(updatedTimeouts))
        }, timeoutDuration);
      }
      setLoading(false)

    } catch (err) {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (isConfigurastionModal) {
      GetPayoutConfiguration();
    }
  }, [isConfigurastionModal]);
  return (
    <>
      <Modal
        className="add-manual-payout-modal"
        isOpen={isConfigurastionModal}
        toggle={onClose}
        centered={true}
      >
        <div className="modal-header">
          <div className="d-flex justify-content-between">
            <h3 className="modal-title mt-0">Bank Configuration</h3>
          </div>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className={`max-h-modal less-h-modal ${isGetLoading ? "opacity-25" : ""}`}
        >
          <div className="modal-body">
            <div className="d-flex justify-content-end pb-1">
              <Button
                color="primary"
                className="float-right mx-1"
                onClick={() => LowBalPayoutToInitializeReset()}
                disabled={Object?.keys?.(storedTimeouts || {})?.includes("1") || loading}
              >
                Reset Low Balance
              </Button>
              <Button
                color="primary"
                className="float-right"
                onClick={() => Recheck_reset()}
              >
                Reset Recheck
              </Button>
            </div>
            <div className="d-flex">
              <Switch
                size="small"
                name="autoTransferAllow"
                checked={watch("autoTransferAllow") === 1 ? true : false}
                onChange={(e) =>
                  setValue(
                    "autoTransferAllow",
                    e.target?.checked === true ? 1 : 0
                  )
                }
              />
              <p>Is Auto Withdraw Enable</p>
            </div>
            <div className="d-flex">
              <Switch
                size="small"
                name="isWithdrawalStsCallAllow"
                checked={watch("isWithdrawalStsCallAllow") === 1 ? true : false}
                onChange={(e) =>
                  setValue(
                    "isWithdrawalStsCallAllow",
                    e.target?.checked ? 1 : 0
                  )
                }
              />
              <p>Is Withdraw Status Call Enable</p>
            </div>
            <div className="d-flex">
              <Switch
                size="small"
                name="smallFirst"
                checked={watch("smallFirst") === 1 ? true : false}
                onChange={(e) =>
                  setValue("smallFirst", e.target?.checked ? 1 : 0)
                }
              />
              <p>Is Small First</p>
            </div>
            <div className="d-flex">
              <Switch
                size="small"
                name="largeFirst"
                checked={watch("largeFirst") === 1 ? true : false}
                onChange={(e) =>
                  setValue("largeFirst", e.target?.checked ? 1 : 0)
                }
              />
              <p>Is Large First</p>
            </div>
            <div className="d-flex">
              <Switch
                size="small"
                name="isAutoLevelAlive"
                checked={watch("isAutoLevelAlive") === 1 ? true : false}
                onChange={(e) =>
                  setValue("isAutoLevelAlive", e.target?.checked ? 1 : 0)
                }
              />
              <p>Is Auto Level</p>
            </div>
            <div className="d-flex">
              <Switch
                size="small"
                name="isCentralActive"
                checked={watch("isCentralActive") === 1 ? true : false}
                onChange={(e) =>
                  setValue("isCentralActive", e.target?.checked ? 1 : 0)
                }
              />
              <p>Is Central Active</p>
            </div>
            <ControlledInput
              name="highManualShiftLimit"
              label="Max Manual Transfer Limit"
              type="number"
              placeholder="Enter High Manual Shift Limit"
              control={control}
            />
            <ControlledInput
              name="lowManualShiftLimit"
              label="Min Manual Transfer Limit"
              type="number"
              placeholder="Enter Low Manual Shift Limit"
              control={control}
            />
            <ControlledInput
              name="highLowbalLimit"
              label="Max LowBal Limit"
              type="number"
              placeholder="Enter High LowBal Limit"
              control={control}
            />
            <ControlledInput
              name="highWaitingLimit"
              label="Max Pending Limit"
              type="number"
              placeholder="Enter High Waiting Limit"
              control={control}
            />
            <ControlledInput
              name="highFinalFailedLimit"
              label="Max Last Failed Limit"
              type="number"
              placeholder="Enter High Final Failed Limit"
              control={control}
            />
            <ControlledInput
              name="lowAutoShiftLimit"
              label="Min Auto Transfer Limit"
              type="number"
              placeholder="Enter Low Auto Shift Limit"
              control={control}
            />
            <ControlledInput
              name="highAutoShiftLimit"
              label="Max Auto Transfer Limit"
              type="number"
              placeholder="Enter High Auto Shift Limit"
              control={control}
            />
            <ControlledInput
              name="withdrawaldelayedInSeconds"
              label="Withdraw Delay in Seconds"
              type="number"
              placeholder="Enter Withdraw Delay in Seconds"
              control={control}
            />
          </div>
          <div className="modal-footer">
            <div className="d-flex justify-content-end button-space">
              <Button className="graybutton" onClick={() => onClose()}>
                Cancel
              </Button>
              <CustomeButton
                type="submit"
                className="submitbutton"
                disabled={isGetLoading}
                isButtonLoading={isButtonLoading}
              >
                Update
              </CustomeButton>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ConfigurationModal;
