import React, { useState } from "react";
import {
  get_bank_turnover,
} from "../../../redux/slices/payinPayoutSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
} from "reactstrap";
import { IoFilterOutline } from "react-icons/io5";
import { Switch } from "@mui/material";
import { Table, TableCell, TableRow } from "../../../components/Table";
import {
  dateFormatter,
  getCurrentMonthDates,
  responseToaster,
  seperator,
} from "../../../helperFunctions";
import { Filter_Data_Transaction, PaymentMetaLabelListGet } from "../../../redux/slices/transactionSlice";
import { FILTER } from "../../../constant";
import { useForm } from "react-hook-form";
import ControlledDropDown from "../../../components/Custom/Forms/Controller/ControlledDropDown";
import ControlledDatePicker from "../../../components/Custom/Forms/Controller/ControlledDatePicker";
import { useRole } from "../../../redux/slices/authSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const BankTurnOver = () => {
  const dispatch = useDispatch();
  const { startOfMonth, endOfMonth } = getCurrentMonthDates();
  const { getBankTurnOverLoading, getBankTurnOverState } = useSelector(
    (state) => state.payinPayout
  );
  const { transactionFilterState, paymentMetaLabelListGetData } = useSelector((state) => state.transaction);
  const role = useRole("Bank Turnover");

  const getFilterTransactions = async () => {
    try {
      await dispatch(Filter_Data_Transaction()).unwrap();
    } catch (err) { }
  };

  const initialValues = {
    ...FILTER,
    date: [startOfMonth, endOfMonth],
    pgName: null,
    bankId: null,
  };

  const [filter, setFilter] = useState(initialValues);
  const [filterColumns, setFilterColumns] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const columns = [
    {
      title: "Date",
      name: "txxnDate",
    },
    {
      title: "AMOUNT",
      name: "total_amount",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.total_amount, false)}</span>
          </>
        );
      },
    },
    {
      title: "Count",
      name: "txnCount",
      Cell: (data) => {
        return (
          <>
            <span>{seperator(data?.txnCount, false)}</span>
          </>
        );
      },
    },
  ];

  const preparepgDataOptions = () => {
    const options = transactionFilterState?.pg_data
      ?.slice()
      ?.sort((a, b) => a?.pg_name?.localeCompare?.(b?.pg_name))
      ?.map((val) => {
        const pgDataOption = { value: val?.pg_id, label: val?.pg_name };
        return pgDataOption;
      });
    return options;
  };

  const getpgAccDataOptions = () => {
    const options = paymentMetaLabelListGetData?.data
      ?.slice()
      ?.sort((a, b) => a?.note?.localeCompare(b?.note))
      ?.map((val) => {
        const pgAccDataOption = { value: val?.acId, label: val?.note };
        return pgAccDataOption;
      });
    return options;
  };

  const Get_Bank_Turnover = async () => {
    try {
      const { isFilter, date, pgName, page_no,
        limit, ...rest } = filter;
      const pgNamee = transactionFilterState?.pg_data?.find?.((data) => { return data?.pg_id == pgName })

      const payload = {
        page_no,
        limit,
        filter_data: {
          startDate: dateFormatter(date?.[0] || startOfMonth, "start"),
          endDate: dateFormatter(
            date?.[1] || endOfMonth,
            "end",
            date?.[0]
          ),
          pgName: pgNamee?.pg_name || "All",
          ...rest,
        },
      }
      const res = await dispatch(get_bank_turnover(payload)).unwrap();
      if (isFilter) {
        responseToaster(res);
      }
    } catch (err) { }
  };

  useEffect(() => {
    getFilterTransactions();
    const newColumns = columns?.map?.((item) => item?.name);
    setFilterColumns(newColumns);
  }, []);

  useEffect(() => {
    if (role.includes("bankTurnover-list") && filter?.isFilter) {
      Get_Bank_Turnover();
    }
  }, [filter]);

  const onSubmit = async () => {
    const values = getValues();
    setFilter({
      ...filter,
      ...values,
      isFilter: true,
    });
  };

  const schema = Yup.object({
    pgName: Yup.string().nullable().required("Please Select Pg"),
    bankId: Yup.string().nullable().required("Please Select Pg Account"),
  });

  const { control, getValues, setValue, handleSubmit, formState: { errors }, reset, watch } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const PaymentMetaLabelList = async () => {
    try {
      const pgName = transactionFilterState?.pg_data?.find((data) => { return data?.pg_id == watch("pgName") })
      let payload = {
        pgType: "payin",
        pgName: pgName?.pg_name ? pgName?.pg_name : "All",
        pg_id: watch("pgName") || "all",
      };
      await dispatch(PaymentMetaLabelListGet(payload)).unwrap();
    } catch (e) { }
  };
  useEffect(() => {
    if (watch("pgName")) {
      PaymentMetaLabelList();
      setValue("bankId", null)
    }
  }, [watch("pgName")]);

  if (!role.includes("bankTurnover-list")) {
    return;
  }
  const downloadFileFromURL = () => {
    try {
      const fileURL = `${process.env.REACT_APP_BASE_URL}api/bank-turnover-excel/${watch("bankId")}/${dateFormatter(filter?.date?.[0] || startOfMonth, "start").replace(/ /g, "")}/${dateFormatter(filter?.date?.[1] || endOfMonth, "end").replace(/ /g, "")}`;
      const a = document.createElement("a");
      a.href = fileURL;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.log('error', error)
    }

  };

  return (
    <>
      <div className="support-log-main-div">
        <Card>
          <CardHeader className="flex-column  align-items-start">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <div>
                <h4 className="main-title">
                  <b>Bank Turnover</b>
                </h4>
              </div>

              <div className="d-flex">
                <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
                  <DropdownToggle
                    className=" header-item waves-effect border-0 bg-transparent table-filterbtn"
                    id="page-header-user-dropdown"
                    tag="button"
                  >
                    <IoFilterOutline fill="#7367F0" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="z-3"
                    style={{
                      whiteSpace: "nowrap",
                      padding: 20,
                      transform: "translate(-20px, 22px)",
                    }}
                  >
                    <li className="z-3 text-dark">
                      <span>
                        <i className="fa-solid fa-filter"></i> Filter Column
                      </span>
                    </li>
                    <div className="border-bottom border-light mt-2">
                      {columns?.map((column) => {
                        return (
                          <div className="d-flex justify-content-between">
                            <p>{column?.title}</p>
                            <Switch
                              checked={filterColumns?.includes(column?.name)}
                              size="small"
                              onChange={(e) => {
                                const newData = [...filterColumns];
                                const index = newData?.indexOf(column?.name);
                                if (index > -1) {
                                  newData.splice(index, 1);
                                } else {
                                  newData.push(column?.name);
                                }
                                setFilterColumns(newData);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Form
              className="d-flex my-xl-auto right-content align-items-start button-space"
              style={{ flexWrap: "wrap" }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <ControlledDropDown
                label="PG"
                name="pgName"
                options={preparepgDataOptions()}
                control={control}
                errors={errors}
              />
              <ControlledDropDown
                label="PG Account"
                name="bankId"
                options={getpgAccDataOptions()}
                control={control}
                errors={errors}
              />
              <ControlledDatePicker
                name="date"
                maxDate={new Date()}
                placeholder="Select Date"
                label="Date"
                selectsRange
                control={control}
              />
              <div className="d-flex button-space mt-2">
                <Button type="submit" color="primary">
                  Apply
                </Button>
                <Button
                  type="button"
                  color="danger"
                  onClick={() => {
                    setFilter({ ...initialValues });
                    reset();
                  }}
                >
                  Clear
                </Button>
                <Button
                  type="button"
                  color="btn btn-primary border-0"
                  onClick={() => downloadFileFromURL()}
                  disabled={!getBankTurnOverState?.data?.length}
                >
                  Download Excel
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <Table
            columns={columns}
            isLoading={getBankTurnOverLoading}
            data={getBankTurnOverState?.data || []}
            isData={!!getBankTurnOverState?.data?.length}
            count={getBankTurnOverState?.data?.length || 0}
            filterColumns={filterColumns}
            isExpandable={false}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
          >
            {getBankTurnOverState?.data?.map?.((item) => {
              return (
                <TableRow
                  columns={columns}
                  item={item}
                  filterColumns={filterColumns}
                  isExpandable={false}
                />
              );
            })}
            <tr style={{
              borderWidth: 3,
            }}>
              <TableCell>Total</TableCell>
              <TableCell>
                {seperator(getBankTurnOverState?.totalAmount) || "-"}
              </TableCell>
              <TableCell>
                {seperator(getBankTurnOverState?.totalCount) || "-"}
              </TableCell>
            </tr>
          </Table>
        </Card>
      </div>
    </>
  );
};

export default BankTurnOver;
