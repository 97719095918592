import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "reactstrap";
import CustomeButton from "../../../components/Custom/Forms/CustomeButton/CustomeButton";
import { responseToaster } from "../../../helperFunctions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { passwordReset } from "../../../redux/slices/smsSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ControlledInput from "../../../components/Custom/Forms/Controller/ControlledInput";
import EyeOff from "../../../assets/images/pages/eye-off.svg";
import EyeOn from "../../../assets/images/pages/eye-on.svg";
import { useRole } from "../../../redux/slices/authSlice";
import { showToaster } from "../../../constant";

const ResetPassword = (props) => {
  const { onCloseUserResetPassword, userResetPassword, onGet } = props;
  const [isShow, setIsShow] = useState(false);
  const [isButtonLoading, setIsLoadingResetPassword] = useState(false);
  const dispatch = useDispatch();

  const role = useRole("Sms Sync");

  const schema = yup.object({
    password: yup.string().required("Please enter password"),
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), defaultValues: {} });

  const onClose = () => {
    onCloseUserResetPassword();
    reset?.()
  };

  const handleButtonClick = async () => {
    try {
      setIsLoadingResetPassword(true);
      const values = getValues();
      let payload = {
        ...values,
      };
      const res = await dispatch(passwordReset({
        id: userResetPassword?.id,
        ...payload,
      })).unwrap();

      if (res?.status) {
        showToaster(res?.message);
        onGet?.();
        onClose();
      }
      setIsLoadingResetPassword(false);
    } catch (err) {
      setIsLoadingResetPassword(false);
      console.error("Error:", err);
    }
  };

  return (
    <Modal isOpen={userResetPassword} toggle={onClose} centered={true}>
      <div>
        <div className="modal-header">
          <h3 className="modal-title mt-0">Reset Password!</h3>
          <i
            onClick={() => {
              onClose();
            }}
            className="fa-solid fa-xmark"
          ></i>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(handleButtonClick)}>
            <div className="row">
              <div className="col-12">
                <ControlledInput
                  type={isShow ? "text" : "password"}
                  name="password"
                  autoComplete="new-password"
                  label="Password"
                  className="w-100 mb-50"
                  inputClassName="py-50"
                  placeholder="Enter your password"
                  rightIcon={
                    <img
                      src={isShow ? EyeOn : EyeOff}
                      alt="Eye"
                      onClick={() => setIsShow(!isShow)}
                    />
                  }
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="flex justify-end items-center gap-2 !pt-4">
              <div className="d-flex justify-content-end w-full items-center pt-2">
                <div className="d-flex justify-content-end button-space">
                  <Button type="button" className="graybutton" onClick={() => {
                    onClose()
                  }}>
                    Cancel
                  </Button>
                  {role.includes("reset-sms-user-password") ? <CustomeButton className="submitbutton" type="submit" isButtonLoading={isButtonLoading}>
                    Update
                  </CustomeButton> : null}
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPassword;
